




















































































































































































































































.cj {
  border-collapse: separate;
  /* 确保边框分开，以便能看到间距效果 */
  border-spacing: 0;

  /* 设置表格单元格的间距 */
  td {
    padding: 0;
    /* 设置单元格内容与边框之间的内边距 */
    line-height: 30px;
    /* 设置单元格与其他单元格之间的外边距 */
    padding-left: 10px;
  }
}
