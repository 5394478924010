
















































































































































































































































































































































































































.exportdialog {
  .upload-workers {
    width: 80px;
    height: 35px;

    .el-upload {
      width: 100% !important;
      height: 35px !important;
      border: none !important;

      .el-button {
        width: 100% !important;
        height: 32px !important;
        padding: 5px 0;
      }
    }
  }

  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
